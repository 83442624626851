import "core-js/modules/es.array.splice.js";
import { get_trip_list, post_trip_list } from "../../api/index";
import tinymce from 'tinymce/tinymce'; // tinymce默认hidden，不引入不显示

import Editor from '@tinymce/tinymce-vue';
import 'tinymce/skins/content/default/content.css';
import 'tinymce/themes/silver/theme.min.js';
import 'tinymce/icons/default/icons';
import 'tinymce/icons/default/icons.min.js';
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
export default {
  components: {
    Editor: Editor
  },
  name: "basetable",
  data: function data() {
    var _this = this;

    return {
      value2: null,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      hoteldatares: {
        id: "",
        keyword: this.keyword,
        // 搜索
        page_index: 1,
        page_size: 5
      },
      addform: {
        type: "add",
        title: this.title,
        desc: this.desc,
        level: this.level,
        price: this.price,
        facility_id_arr: this.facility_id_arr,
        start_time: this.start_time,
        end_time: this.end_time,
        traffic: this.traffic,
        shop: this.shop,
        team_num: this.team_num,
        stay: this.stay,
        scenic_spot: this.scenic_spot,
        food: this.food,
        language: this.language,
        with_others: this.with_others,
        highlight: this.highlight,
        arrange: this.arrange,
        image_url: ' ',
        theme: this.theme
      },
      editform: {
        type: "edit",
        ship_name: this.ship_name,
        title: this.title,
        desc: this.desc,
        price: this.price,
        image_url: '',
        start_time: this.start_time,
        end_time: this.end_time
      },
      query: {
        address: "",
        name: ""
      },
      rules: {
        ship_name: [{
          required: true,
          message: "请输入游轮名称",
          trigger: "blur"
        }],
        title: [{
          required: true,
          message: "请输入旅游名称",
          trigger: "blur"
        }],
        desc: [{
          required: true,
          message: "请输入品牌描述",
          trigger: "blur"
        }],
        price: [{
          required: true,
          message: "请输入价格",
          trigger: "blur"
        }]
      },
      init: {
        language_url: '/tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/tinymce/skins/lightgray',
        height: 300,
        plugins: 'link lists image code table colorpicker textcolor wordcount contextmenu',
        toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat',
        branding: false,
        paste_word_valid_elements: '*[*]',
        // word需要它
        paste_data_images: true,
        // 粘贴的同时能把内容里的图片自动上传，非常强力的功能
        paste_convert_word_fake_lists: false,
        // 插入word文档需要该属性
        // 配置图片上传的功能
        images_upload_handler: function images_upload_handler(blobInfo, success, failure) {
          _this.handleImgUpload(blobInfo, success, failure);
        }
      },
      tableData: [],
      editVisible: false,
      addVisible: false,
      pageTotal: 0,
      permissionBeans: []
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData: function getData() {
      var _this2 = this;

      get_trip_list(this.hoteldatares).then(function (res) {
        _this2.tableData = res.list;
        _this2.pageTotal = res.count;
      });
    },
    //创建时间格式化
    timestampToTime: function timestampToTime(row) {
      if (row.start_time == 0) {
        return "-";
      } else {
        var date = new Date(row.start_time * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate() + 1) : date.getDate() + 1) + ' ';
        var h = (date.getHours() + 1 < 10 ? '0' + (date.getHours() + 1) : date.getHours() + 1) + ':';
        var m = (date.getMinutes() + 1 < 10 ? '0' + (date.getMinutes() + 1) : date.getMinutes() + 1) + ':';
        var s = (date.getSeconds() + 1 < 10 ? '0' + (date.getSeconds() + 1) : date.getSeconds() + 1) + ' ';
        return Y + M + D;
      }
    },
    timestampToTime2: function timestampToTime2(row) {
      if (row.end_time == 0) {
        return "-";
      } else {
        var date = new Date(row.end_time * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate() + 1) : date.getDate() + 1) + ' ';
        var h = (date.getHours() + 1 < 10 ? '0' + (date.getHours() + 1) : date.getHours() + 1) + ':';
        var m = (date.getMinutes() + 1 < 10 ? '0' + (date.getMinutes() + 1) : date.getMinutes() + 1) + ':';
        var s = (date.getSeconds() + 1 < 10 ? '0' + (date.getSeconds() + 1) : date.getSeconds() + 1) + ' ';
        return Y + M + D;
      }
    },
    // 删除操作
    handleDelete: function handleDelete(index, row) {
      var _this3 = this;

      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      }).then(function () {
        console.log(row);
        var data = {
          id: row.id,
          type: "delete"
        };
        post_trip_list(data).then(function (res) {
          if (res.code == 1) {
            console.log(res);

            _this3.$message.success("删除成功");

            _this3.tableData.splice(index, 1);

            _this3.getData();
          } else {
            _this3.$message.error(res.msg);
          }
        });
      }).catch(function () {});
    },
    addmenu: function addmenu() {
      this.addVisible = true;
    },
    // 添加保存
    addmenuFun: function addmenuFun(addform) {
      var _this4 = this;

      console.log(this.addform);
      this.$refs[addform].validate(function (valid) {
        if (valid) {
          post_trip_list(_this4.addform).then(function (res) {
            if (res.code == 1) {
              _this4.addVisible = false;

              _this4.$message.success(res.msg);

              _this4.getData();
            } else {
              console.log(res);

              _this4.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑操作
    handleEdit: function handleEdit(index, row) {
      this.idx = index;
      this.editform.id = row.id;
      this.editform.ship_name = row.ship_name;
      this.editform.title = row.title;
      this.editform.desc = row.desc;
      this.editform.price = row.price;
      this.editform.image_url = row.image_url;
      this.editform.start_time = row.start_time;
      this.editform.end_time = row.end_time;
      this.editVisible = true;
    },
    addsmall: function addsmall() {
      this.$message.success("\u6DFB\u52A0\u5B50\u83DC\u5355");
    },
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      console.log(file); // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
    },
    // 保存编辑
    saveEdit: function saveEdit() {
      var _this5 = this;

      post_ship_list(this.editform).then(function (res) {
        if (res.code == 1) {
          _this5.editVisible = false;

          _this5.$message.success(res.msg);

          _this5.getData();
        } else {
          _this5.$message.error(res.msg);
        }
      });
    },
    // 分页导航
    handlePageChange: function handlePageChange(val) {
      //  this.$set(this.query, "pageIndex", val);
      this.getData();
    },
    // 用于上传图片的，后端需要提供好上传接口
    handleImgUpload: function handleImgUpload(blobInfo, success, failure) {
      var formdata = new FormData();
      formdata.set('upload_file', blobInfo.blob());
      var new_headers = {
        headers: this.headers
      };
      var upload_url = process.env.BASE_API + '/website/uploadfile';
      axios.post(upload_url, formdata, new_headers).then(function (res) {
        // console.log(res.data.data)
        success(res.data.data[0]);
      }).catch(function (res) {
        failure('error');
      });
    }
  },
  mounted: function mounted() {
    tinymce.init({});
  }
};